import actionTypes from "../actionTypes";

const initialState = {
	threads: [],
	threadsLoading: false,
	thread: [],
	threadLoading: false,
	mensaje: "",
};

const mensajesReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.MENSAJES_THREADS_LOAD_INIT:
			return {
				...state,
				threadsLoading: true,
			};
		case actionTypes.MENSAJES_THREADS_LOAD_SUCCESS:
			return {
				...state,
				threads: payload,
				threadsLoading: false,
			};
		case actionTypes.MENSAJES_THREADS_LOAD_ERROR:
			return {
				...state,
				threadsLoading: false,
			};
		case actionTypes.MENSAJES_LOAD_INIT:
			return {
				...state,
				threadLoading: true,
			};
		case actionTypes.MENSAJES_LOAD_SUCCESS:
			return {
				...state,
				thread: payload,
				threadLoading: false,
			};
		case actionTypes.MENSAJES_LOAD_ERROR:
			return {
				...state,
				threadLoading: false,
			};
		case actionTypes.MENSAJE_SET:
			return {
				...state,
				mensaje: payload,
			};
		case actionTypes.MENSAJES_SAVE_SUCCESS:
			return {
				...state,
				mensaje: "",
			};
		default:
			return state;
	}
};

export default mensajesReducer;
