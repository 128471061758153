import API from "../../classes/api";
import { put, call } from "redux-saga/effects";
import actionTypes from "../actionTypes";

export function* processLocalLoginInit(action) {
	console.log("authSagas/processLocalLoginInit", action);
	const { language, email, password } = action.payload;
	try {
		const response = yield call(API.localLogin, language, email, password);
		yield localStorage.setItem("token", response.data.token);
		yield put({
			type: actionTypes.AUTH_LOCAL_LOGIN_SUCCESS,
			payload: response,
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_LOCAL_LOGIN_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processSilentLoginSuccess(action) {
	console.log("authSagas/processSilentLoginSuccess", action);
	const { token } = action.payload.data;
	try {
		yield localStorage.setItem("token", token);
	} catch (error) {}
}

export function* processSilentLoginError(action) {
	console.log("authSagas/processSilentLoginError", action);
	try {
		yield localStorage.removeItem("token");
	} catch (error) {}
}

export function* processLogout(action) {
	console.log("authSagas/processLogout", action);
	try {
		yield localStorage.removeItem("token");
	} catch (error) {}
}

export function* processPerfilSaveInit(action) {
	console.log("authSagas/processPerfilSaveInit", action);
	const { language, data } = action.payload;
	try {
		const response = yield call(API.savePerfil, language, data);
		yield localStorage.setItem("token", response.data.token);
		yield put({
			type: actionTypes.AUTH_PERFIL_SAVE_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.AUTH_SILENT_LOGIN_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.APP_REDIRECT,
			payload: { to: "/micuenta?tab=perfil" },
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_PERFIL_SAVE_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processLocalRegisterInit(action) {
	console.log("authSagas/processLocalRegisterInit", action);
	const { language, data } = action.payload;
	try {
		const response = yield call(API.localRegister, language, data);
		yield localStorage.setItem("token", response.data.token);
		yield put({
			type: actionTypes.AUTH_LOCAL_REGISTER_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.AUTH_SILENT_LOGIN_SUCCESS,
			payload: response,
		});
		if (typeof window !== "undefined") {
			// window.gtag("event", "conversion", { send_to: "AW-609429651/lImoCN_whuECEJPRzKIC" });
		}
		yield put({
			type: actionTypes.APP_REDIRECT,
			payload: { to: "/micuenta" },
		});
		yield put({
			type: actionTypes.AUTH_LOCAL_REGISTER_RESET,
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_LOCAL_REGISTER_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processSocialLoginInit(action) {
	console.log("authSagas/processSocialLoginInit");
	const { language, provider, token } = action.payload;
	try {
		const response = yield call(API.socialLogin, language, provider, token);
		yield localStorage.setItem("token", response.data.token);
		yield put({
			type: actionTypes.AUTH_SOCIAL_LOGIN_SUCCESS,
			payload: response,
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_SOCIAL_LOGIN_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processLocalRegisterValidateInit(action) {
	console.log("authSagas/processLocalRegisterValidateInit", action);
	const { language, data } = action.payload;
	try {
		yield call(API.localRegisterValidate, language, data);
		yield put({
			type: actionTypes.AUTH_LOCAL_REGISTER_VALIDATE_SUCCESS,
		});
		console.log("authSagas/processLocalRegisterValidateInit OK");
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_LOCAL_REGISTER_VALIDATE_ERROR,
			payload: error.data.response.payload,
		});
		console.log("authSagas/processLocalRegisterValidateInit ERROR");
	}
}

export function* processPasswordRecoveryInit(action) {
	console.log("authSagas/processPasswordRecoveryInit", action);
	const { language, data } = action.payload;
	try {
		const response = yield call(API.passwordRecovery, language, data);
		yield put({
			type: actionTypes.AUTH_PASSWORD_RECOVERY_SUCCESS,
			payload: response,
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_PASSWORD_RECOVERY_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processPasswordChangeInit(action) {
	console.log("authSagas/processPasswordChangeInit", action);
	const { language, data } = action.payload;
	try {
		const response = yield call(API.passwordChange, language, data);
		yield put({
			type: actionTypes.AUTH_PASSWORD_CHANGE_SUCCESS,
			payload: response,
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_PASSWORD_CHANGE_ERROR,
			payload: error.data.response.payload,
		});
	}
}
