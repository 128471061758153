import actionTypes from "../actionTypes";
import { put, call } from "redux-saga/effects";
import API from "../../classes/api";

export function* processAppBootInit(action) {
	try {
		const boot = yield call(API.getBoot, action.payload); // en el payload solo viene el language
		yield put({
			type: actionTypes.APP_BOOT_SUCCESS,
			payload: boot,
		});
		// token
		const token = yield localStorage.getItem("token");
		if (token) {
			try {
				const usuario = yield call(API.getPerfil, action.payload, token);
				yield put({
					type: actionTypes.AUTH_SILENT_LOGIN_SUCCESS,
					payload: usuario,
				});
			} catch (error) {
				yield put({
					type: actionTypes.AUTH_SILENT_LOGIN_ERROR,
				});
			}
		} else {
			yield put({
				type: actionTypes.AUTH_SILENT_LOGIN_ERROR,
			});
		}
		// geo
		// const geo = yield localStorage.getItem("geo");
		// if (geo) {
		// 	yield put({
		// 		type: actionTypes.APP_GEO_SET,
		// 		payload: JSON.parse(geo),
		// 	});
		// }
		// popup home
		const popupHomeLastShown = localStorage.getItem("popupHomeLastShown");
		const now = new Date();
		if (!popupHomeLastShown) {
			// nunca fue mostrado, mostrarlo y guardar la fecha
			localStorage.setItem("popupHomeLastShown", now.getTime());
			yield put({
				type: actionTypes.APP_UI_POPUP_HOME_SHOW,
			});
		} else {
			// ya fue mostrado, calcular si paso x cantidad de tiempo para volver a mostrarlo
			const lastShown = new Date(parseInt(popupHomeLastShown));
			const diffHoras = (now.getTime() - lastShown.getTime()) / 1000 / 60 / 60;
			if (diffHoras > 24) {
				// ya pasaron 24 horas, volver a mostrarlo
				localStorage.setItem("popupHomeLastShown", now.getTime());
				yield put({
					type: actionTypes.APP_UI_POPUP_HOME_SHOW,
				});
			}
		}
	} catch (error) {
		yield put({
			type: actionTypes.APP_BOOT_ERROR,
		});
	}
}

export function* processAppAnunciosHomeLoadInit(action) {
	const { language, params } = action.payload;
	console.log("processAppAnunciosHomeLoadInit", action.payload);
	try {
		const anuncios = yield call(API.getAnuncios, language, null, params);
		yield put({
			type: actionTypes.APP_ANUNCIOS_HOME_LOAD_SUCCESS,
			payload: {
				area: params.area,
				anuncios: anuncios.data.anuncios,
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.APP_ANUNCIOS_HOME_LOAD_ERROR,
		});
	}
}
