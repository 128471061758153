const actionTypes = {
	APP_BOOT_INIT: "APP_BOOT_INIT",
	APP_BOOT_SUCCESS: "APP_BOOT_SUCCESS",
	APP_BOOT_ERROR: "APP_BOOT_ERROR",

	APP_NETWORK_ERROR_RESET: "APP_NETWORK_ERROR_RESET",

	APP_UI_SIDENAV_TOGGLE: "APP_UI_SIDENAV_TOGGLE",
	APP_UI_DROPDOWNMENU_TOGGLE: "APP_UI_DROPDOWNMENU_TOGGLE",
	APP_UI_POPUP_LOGIN_SHOW: "APP_UI_POPUP_LOGIN_SHOW",
	APP_UI_POPUP_LOGIN_HIDE: "APP_UI_POPUP_LOGIN_HIDE",
	APP_UI_POPUP_ELIMINAR_ANUNCIO_SHOW: "APP_UI_POPUP_ELIMINAR_ANUNCIO_SHOW",
	APP_UI_POPUP_ELIMINAR_ANUNCIO_HIDE: "APP_UI_POPUP_ELIMINAR_ANUNCIO_HIDE",
	APP_UI_POPUP_TOOLTIP_SHOW: "APP_UI_POPUP_TOOLTIP_SHOW",
	APP_UI_POPUP_TOOLTIP_HIDE: "APP_UI_POPUP_TOOLTIP_HIDE",
	APP_UI_POPUP_ELIGECOMUNIDADES_SHOW: "APP_UI_POPUP_ELIGECOMUNIDADES_SHOW",
	APP_UI_POPUP_ELIGECOMUNIDADES_HIDE: "APP_UI_POPUP_ELIGECOMUNIDADES_HIDE",
	APP_UI_POPUP_FILTROZONA_SHOW: "APP_UI_POPUP_FILTROZONA_SHOW",
	APP_UI_POPUP_FILTROZONA_HIDE: "APP_UI_POPUP_FILTROZONA_HIDE",
	APP_UI_POPUP_MASFILTROS_SHOW: "APP_UI_POPUP_MASFILTROS_SHOW",
	APP_UI_POPUP_MASFILTROS_HIDE: "APP_UI_POPUP_MASFILTROS_HIDE",
	APP_UI_POPUP_ORDENAR_SHOW: "APP_UI_POPUP_ORDENAR_SHOW",
	APP_UI_POPUP_ORDENAR_HIDE: "APP_UI_POPUP_ORDENAR_HIDE",
	APP_UI_POPUP_PETICION_MENSAJE_SHOW: "APP_UI_POPUP_PETICION_MENSAJE_SHOW",
	APP_UI_POPUP_PETICION_MENSAJE_HIDE: "APP_UI_POPUP_PETICION_MENSAJE_HIDE",
	APP_UI_POPUP_HOME_SHOW: "APP_UI_POPUP_HOME_SHOW",
	APP_UI_POPUP_HOME_HIDE: "APP_UI_POPUP_HOME_HIDE",
	APP_UI_POPUP_TEXTO_SHOW: "APP_UI_POPUP_TEXTO_SHOW",
	APP_UI_POPUP_TEXTO_HIDE: "APP_UI_POPUP_TEXTO_HIDE",
	APP_UI_POPUP_TYPEFORM_SHOW: "APP_UI_POPUP_TYPEFORM_SHOW",
	APP_UI_POPUP_TYPEFORM_HIDE: "APP_UI_POPUP_TYPEFORM_HIDE",
	APP_UI_POPUP_MAP_SHOW: "APP_UI_POPUP_MAP_SHOW",
	APP_UI_POPUP_MAP_HIDE: "APP_UI_POPUP_MAP_HIDE",
	APP_UI_POPUP_VIDEO_SHOW: "APP_UI_POPUP_VIDEO_SHOW",
	APP_UI_POPUP_VIDEO_HIDE: "APP_UI_POPUP_VIDEO_HIDE",

	APP_SET_IS_MOBILE: "APP_SET_IS_MOBILE",

	APP_GEO_SET: "APP_GEO_SET",
	APP_AREA_SET: "APP_AREA_SET",

	APP_ANUNCIOS_HOME_INIT: "APP_ANUNCIOS_HOME_INIT",
	APP_ANUNCIOS_HOME_LOAD_INIT: "APP_ANUNCIOS_HOME_LOAD_INIT",
	APP_ANUNCIOS_HOME_LOAD_SUCCESS: "APP_ANUNCIOS_HOME_LOAD_SUCCESS",
	APP_ANUNCIOS_HOME_LOAD_ERROR: "APP_ANUNCIOS_HOME_LOAD_ERROR",

	APP_UI_ERRORS_SHOW: "APP_UI_ERRORS_SHOW",
	APP_UI_ERRORS_HIDE: "APP_UI_ERRORS_HIDE",
	APP_UI_SUCCESS_HIDE: "APP_UI_SUCCESS_HIDE",

	AUTH_LOCAL_LOGIN_INIT: "AUTH_LOCAL_LOGIN_INIT",
	AUTH_LOCAL_LOGIN_SUCCESS: "AUTH_LOCAL_LOGIN_SUCCESS",
	AUTH_LOCAL_LOGIN_ERROR: "AUTH_LOCAL_LOGIN_ERROR",

	AUTH_SOCIAL_LOGIN_INIT: "AUTH_SOCIAL_LOGIN_INIT",
	AUTH_SOCIAL_LOGIN_SUCCESS: "AUTH_SOCIAL_LOGIN_SUCCESS",
	AUTH_SOCIAL_LOGIN_ERROR: "AUTH_SOCIAL_LOGIN_ERROR",

	AUTH_SILENT_LOGIN_SUCCESS: "AUTH_SILENT_LOGIN_SUCCESS",
	AUTH_SILENT_LOGIN_ERROR: "AUTH_SILENT_LOGIN_ERROR",

	AUTH_LOGOUT: "AUTH_LOGOUT",

	AUTH_PERFIL_SAVE_INIT: "AUTH_PERFIL_SAVE_INIT",
	AUTH_PERFIL_SAVE_SUCCESS: "AUTH_PERFIL_SAVE_SUCCESS",
	AUTH_PERFIL_SAVE_ERROR: "AUTH_PERFIL_SAVE_ERROR",

	AUTH_LOCAL_REGISTER_VALIDATE_INIT: "AUTH_LOCAL_REGISTER_VALIDATE_INIT",
	AUTH_LOCAL_REGISTER_VALIDATE_SUCCESS: "AUTH_LOCAL_REGISTER_VALIDATE_SUCCESS",
	AUTH_LOCAL_REGISTER_VALIDATE_ERROR: "AUTH_LOCAL_REGISTER_VALIDATE_ERROR",

	AUTH_LOCAL_REGISTER_PAGE_BACK: "AUTH_LOCAL_REGISTER_PAGE_BACK",

	AUTH_LOCAL_REGISTER_INIT: "AUTH_LOCAL_REGISTER_INIT",
	AUTH_LOCAL_REGISTER_SUCCESS: "AUTH_LOCAL_REGISTER_SUCCESS",
	AUTH_LOCAL_REGISTER_ERROR: "AUTH_LOCAL_REGISTER_ERROR",
	AUTH_LOCAL_REGISTER_RESET: "AUTH_LOCAL_REGISTER_RESET",

	AUTH_PASSWORD_RECOVERY_INIT: "AUTH_PASSWORD_RECOVERY_INIT",
	AUTH_PASSWORD_RECOVERY_SUCCESS: "AUTH_PASSWORD_RECOVERY_SUCCESS",
	AUTH_PASSWORD_RECOVERY_ERROR: "AUTH_PASSWORD_RECOVERY_ERROR",

	AUTH_PASSWORD_CHANGE_INIT: "AUTH_PASSWORD_CHANGE_INIT",
	AUTH_PASSWORD_CHANGE_SUCCESS: "AUTH_PASSWORD_CHANGE_SUCCESS",
	AUTH_PASSWORD_CHANGE_ERROR: "AUTH_PASSWORD_CHANGE_ERROR",

	CREAR_ANUNCIO_VALIDATE_INIT: "CREAR_ANUNCIO_VALIDATE_INIT",
	CREAR_ANUNCIO_VALIDATE_SUCCESS: "CREAR_ANUNCIO_VALIDATE_SUCCESS",
	CREAR_ANUNCIO_VALIDATE_ERROR: "CREAR_ANUNCIO_VALIDATE_ERROR",

	CREAR_ANUNCIO_PAGE_BACK: "CREAR_ANUNCIO_PAGE_BACK",

	CREAR_ANUNCIO_INIT: "CREAR_ANUNCIO_INIT",
	CREAR_ANUNCIO_SUCCESS: "CREAR_ANUNCIO_SUCCESS",
	CREAR_ANUNCIO_ERROR: "CREAR_ANUNCIO_ERROR",
	CREAR_ANUNCIO_RESET: "CREAR_ANUNCIO_RESET",

	ANUNCIOS_PROPIOS_LOAD_INIT: "ANUNCIOS_PROPIOS_LOAD_INIT",
	ANUNCIOS_PROPIOS_LOAD_SUCCESS: "ANUNCIOS_PROPIOS_LOAD_SUCCESS",
	ANUNCIOS_PROPIOS_LOAD_ERROR: "ANUNCIOS_PROPIOS_LOAD_ERROR",

	// ANUNCIOS_AJENOS_SET_PAIS: "ANUNCIOS_AJENOS_SET_PAIS",
	// ANUNCIOS_AJENOS_SET_ZONA: "ANUNCIOS_AJENOS_SET_ZONA",
	// ANUNCIOS_AJENOS_SET_LOCALIZACION: "ANUNCIOS_AJENOS_SET_LOCALIZACION",
	ANUNCIOS_AJENOS_SEARCH_SET: "ANUNCIOS_AJENOS_SEARCH_SET",
	ANUNCIOS_AJENOS_RESET: "ANUNCIOS_AJENOS_RESET",

	ANUNCIOS_AJENOS_LOAD_INIT: "ANUNCIOS_AJENOS_LOAD_INIT",
	ANUNCIOS_AJENOS_LOADING: "ANUNCIOS_AJENOS_LOADING",
	ANUNCIOS_AJENOS_LOAD_SUCCESS: "ANUNCIOS_AJENOS_LOAD_SUCCESS",
	ANUNCIOS_AJENOS_LOAD_ERROR: "ANUNCIOS_AJENOS_LOAD_ERROR",

	ANUNCIO_LOAD_INIT: "ANUNCIO_LOAD_INIT",
	ANUNCIO_LOAD_SUCCESS: "ANUNCIO_LOAD_SUCCESS",
	ANUNCIO_LOAD_ERROR: "ANUNCIO_LOAD_ERROR",
	ANUNCIO_RESET: "ANUNCIO_RESET",

	ANUNCIO_SAVE_INIT: "ANUNCIO_SAVE_INIT",
	ANUNCIO_SAVE_SUCCESS: "ANUNCIO_SAVE_SUCCESS",
	ANUNCIO_SAVE_ERROR: "ANUNCIO_SAVE_ERROR",

	ANUNCIO_DELETE_INIT: "ANUNCIO_DELETE_INIT",
	ANUNCIO_DELETE_SUCCESS: "ANUNCIO_DELETE_SUCCESS",
	ANUNCIO_DELETE_ERROR: "ANUNCIO_DELETE_ERROR",

	ANUNCIOCONTACTO_INIT: "ANUNCIOCONTACTO_INIT",
	ANUNCIOCONTACTO_SUCCESS: "ANUNCIOCONTACTO_SUCCESS",
	ANUNCIOCONTACTO_ERROR: "ANUNCIOCONTACTO_ERROR",

	ANUNCIOHOTELES_LOAD_INIT: "ANUNCIOHOTELES_LOAD_INIT",
	ANUNCIOHOTELES_LOAD_SUCCESS: "ANUNCIOHOTELES_LOAD_SUCCESS",
	ANUNCIOHOTELES_LOAD_ERROR: "ANUNCIOHOTELES_LOAD_ERROR",

	APP_REDIRECT: "APP_REDIRECT",
	APP_REDIRECT_RESET: "APP_REDIRECT_RESET",

	CONTACTO_INIT: "CONTACTO_INIT",
	CONTACTO_SUCCESS: "CONTACTO_SUCCESS",
	CONTACTO_ERROR: "CONTACTO_ERROR",

	NEWSLETTER_SUSCRIPCION_ADD_INIT: "NEWSLETTER_SUSCRIPCION_ADD_INIT",
	NEWSLETTER_SUSCRIPCION_ADD_SUCCESS: "NEWSLETTER_SUSCRIPCION_ADD_SUCCESS",
	NEWSLETTER_SUSCRIPCION_ADD_ERROR: "NEWSLETTER_SUSCRIPCION_ADD_ERROR",

	PAGINAS_LOAD_INIT: "PAGINAS_LOAD_INIT",
	PAGINAS_LOAD_SUCCESS: "PAGINAS_LOAD_SUCCESS",
	PAGINAS_LOAD_ERROR: "PAGINAS_LOAD_ERROR",

	MENSAJES_THREADS_LOAD_INIT: "MENSAJES_THREADS_LOAD_INIT",
	MENSAJES_THREADS_LOAD_SUCCESS: "MENSAJES_THREADS_LOAD_SUCCESS",
	MENSAJES_THREADS_LOAD_ERROR: "MENSAJES_THREADS_LOAD_ERROR",
	MENSAJES_LOAD_INIT: "MENSAJES_LOAD_INIT",
	MENSAJES_LOAD_SUCCESS: "MENSAJES_LOAD_SUCCESS",
	MENSAJES_LOAD_ERROR: "MENSAJES_LOAD_ERROR",
	MENSAJES_SAVE_INIT: "MENSAJES_SAVE_INIT",
	MENSAJES_SAVE_SUCCESS: "MENSAJES_SAVE_SUCCESS",
	MENSAJES_SAVE_ERROR: "MENSAJES_SAVE_ERROR",
	MENSAJE_SET: "MENSAJE_SET",
};

export default actionTypes;
