import API from "../../classes/api";
import { put, call } from "redux-saga/effects";
import actionTypes from "../actionTypes";

export function* processCrearAnuncioValidateInit(action) {
	console.log("authSagas/processCrearAnuncioValidateInit", action);
	const { language, data } = action.payload;
	try {
		yield call(API.crearAnuncioValidate, language, data);
		yield put({
			type: actionTypes.CREAR_ANUNCIO_VALIDATE_SUCCESS,
		});
		console.log("authSagas/processCrearAnuncioValidateInit OK");
	} catch (error) {
		yield put({
			type: actionTypes.CREAR_ANUNCIO_VALIDATE_ERROR,
			payload: error.data.response.payload,
		});
		console.log("authSagas/processCrearAnuncioValidateInit ERROR");
	}
};

export function* processCrearAnuncioInit(action) {
	console.log("authSagas/processCrearAnuncioInit", action);
	const { language, data } = action.payload;
	try {
		const response = yield call(API.crearAnuncio, language, data);
		yield put({
			type: actionTypes.CREAR_ANUNCIO_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.APP_REDIRECT,
			payload: { to: "/micuenta" },
		});
	} catch (error) {
		yield put({
			type: actionTypes.CREAR_ANUNCIO_ERROR,
			payload: error.data.response.payload,
		});
	}
};
