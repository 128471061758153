import actionTypes from "../actionTypes";
import { put, call, delay } from "redux-saga/effects";
import API from "../../classes/api";

export function* processMensajesThreadsLoadInit(action) {
	const { language, token } = action.payload;
	try {
		const threads = yield call(API.getMensajesThreads, language, token);
		yield put({
			type: actionTypes.MENSAJES_THREADS_LOAD_SUCCESS,
			payload: threads,
		});
	} catch (error) {
		yield put({
			type: actionTypes.MENSAJES_THREADS_LOAD_ERROR,
		});
	}
}

export function* processMensajesLoadInit(action) {
	const { language, token, anuncio } = action.payload;
	try {
		const thread = yield call(API.getMensajes, language, token, anuncio);
		yield put({
			type: actionTypes.MENSAJES_LOAD_SUCCESS,
			payload: thread,
		});
	} catch (error) {
		yield put({
			type: actionTypes.MENSAJES_LOAD_ERROR,
		});
	}
}

export function* processMensajesSaveInit(action) {
	const { language, data } = action.payload;
	try {
		const response = yield call(API.mensajesSave, language, data);
		yield put({
			type: actionTypes.MENSAJES_SAVE_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.MENSAJES_LOAD_INIT,
			payload: {
				language,
				token: data.token,
				anuncio: data.anuncio,
			},
		});
		yield put({
			type: actionTypes.MENSAJES_THREADS_LOAD_INIT,
			payload: {
				language,
				token: data.token,
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.MENSAJES_SAVE_ERROR,
			payload: error.data.response.payload,
		});
	}
}
