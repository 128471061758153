import actionTypes from "../actionTypes";

const initialState = {
	usuario: {
		id: 0,
		email: "",
		nombre: "",
		apellido: "",
		pais: {
			id: 0,
			title: "",
			intl: {
				title: {
					es: "",
					en: "",
				},
			},
		},
		zona: {
			id: 0,
			title: "",
			intl: {
				title: {
					es: "",
					en: "",
				},
			},
		},
		localizacion: {
			id: 0,
			title: "",
			intl: {
				title: {
					es: "",
					en: "",
				},
			},
		},
		descripcion: "",
		imagen: {
			url: "",
		},
		dni: "",
		telefono: "",
		token: "",
	},
	loggedIn: false,
	resolved: false,
};

const authReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		// auth local login

		case actionTypes.AUTH_LOCAL_LOGIN_SUCCESS:
			return {
				...state,
				loggedIn: true,
				resolved: true,
				usuario: {
					...state.usuario,
					...payload.data,
				},
			};

		case actionTypes.AUTH_LOCAL_LOGIN_ERROR:
			return {
				...state,
				loggedIn: false,
				resolved: true,
			};

		// auth social login

		case actionTypes.AUTH_SOCIAL_LOGIN_SUCCESS:
			return {
				...state,
				loggedIn: true,
				resolved: true,
				usuario: {
					...state.usuario,
					...payload.data,
				},
			};

		// auth silent login

		case actionTypes.AUTH_SILENT_LOGIN_SUCCESS:
			return {
				...state,
				loggedIn: true,
				resolved: true,
				usuario: {
					...state.usuario,
					...payload.data,
				},
			};

		case actionTypes.AUTH_SILENT_LOGIN_ERROR:
			return {
				...state,
				loggedIn: false,
				resolved: true,
				usuario: {
					...state.usuario,
					...initialState.usuario,
				},
			};

		// auth logout

		case actionTypes.AUTH_LOGOUT:
			return {
				...state,
				usuario: initialState.usuario,
				loggedIn: false,
				resolved: true,
			};
		
		default:
			return state;
	}
};

export default authReducer;
