import actionTypes from "../actionTypes";
import { put, call, delay } from "redux-saga/effects";
import API from "../../classes/api";

export function* processAnunciosPropiosLoadInit(action) {
	const { language, token } = action.payload;
	try {
		const anuncios = yield call(API.getAnuncios, language, token);
		yield put({
			type: actionTypes.ANUNCIOS_PROPIOS_LOAD_SUCCESS,
			payload: anuncios,
		});
	} catch (error) {
		yield put({
			type: actionTypes.ANUNCIOS_PROPIOS_LOAD_ERROR,
		});
	}
}

export function* processAnunciosAjenosLoadInit(action) {
	const { language, token, params } = action.payload;
	try {
		const anuncios = yield call(API.getAnuncios, language, token, params);
		yield put({
			type: actionTypes.ANUNCIOS_AJENOS_LOAD_SUCCESS,
			payload: anuncios,
		});
	} catch (error) {
		yield put({
			type: actionTypes.ANUNCIOS_AJENOS_LOAD_ERROR,
		});
	}
}

export function* processAnuncioLoadInit(action) {
	const { language, id } = action.payload;
	try {
		const anuncio = yield call(API.getAnuncio, language, id);
		yield put({
			type: actionTypes.ANUNCIO_LOAD_SUCCESS,
			payload: anuncio,
		});
	} catch (error) {
		yield put({
			type: actionTypes.ANUNCIO_LOAD_ERROR,
		});
	}
}

export function* processAnuncioSaveInit(action) {
	const { language, data } = action.payload;
	try {
		const response = yield call(API.saveAnuncio, language, data);
		yield put({
			type: actionTypes.ANUNCIO_SAVE_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.APP_REDIRECT,
			payload: { to: "/micuenta" },
		});
	} catch (error) {
		yield put({
			type: actionTypes.ANUNCIO_SAVE_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processAnuncioDeleteInit(action) {
	const { language, token, id } = action.payload;
	try {
		const response = yield call(API.deleteAnuncio, language, { token, id });
		yield put({
			type: actionTypes.ANUNCIO_DELETE_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.ANUNCIOS_PROPIOS_LOAD_INIT,
			payload: {
				language,
				token,
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.ANUNCIO_DELETE_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processAnuncioHotelesLoadInit(action) {
	const { language, params } = action.payload;
	try {
		const hoteles = yield call(API.getHoteles, language, params);
		yield put({
			type: actionTypes.ANUNCIOHOTELES_LOAD_SUCCESS,
			payload: hoteles,
		});
	} catch (error) {
		yield put({
			type: actionTypes.ANUNCIOHOTELES_LOAD_ERROR,
		});
	}
}

export function* processAnuncioContactoInit(action) {
	const { language, data } = action.payload;
	try {
		const response = yield call(API.anuncioContacto, language, data);
		yield put({
			type: actionTypes.ANUNCIOCONTACTO_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.APP_REDIRECT,
			payload: {
				to: "/",
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.ANUNCIOCONTACTO_ERROR,
			payload: error.data.response.payload,
		});
	}
}
