import actionTypes from "../actionTypes";

const initialState = {
	pagina: null,
	loading: false,
};

const paginasReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.PAGINAS_LOAD_INIT:
			return {
				...state,
				pagina: initialState.pagina,
				loading: true,
			};

		case actionTypes.PAGINAS_LOAD_SUCCESS:
			return {
				...state,
				pagina: payload,
				loading: false,
			};

		case actionTypes.PAGINAS_LOAD_ERROR:
			return {
				...state,
				pagina: initialState.pagina,
				loading: false,
			};

		default:
			return state;
	}
};

export default paginasReducer;
