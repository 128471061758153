import actionTypes from "./actionTypes";
import { takeLatest, all, takeEvery } from "redux-saga/effects";
import * as appSagas from "./app/sagas";
import * as authSagas from "./auth/sagas";
import * as crearAnuncioSagas from "./crearAnuncio/sagas";
import * as anunciosSagas from "./anuncios/sagas";
import * as contactoSagas from "./contacto/sagas";
import * as newsletterSagas from "./newsletter/sagas";
import * as paginasSagas from "./paginas/sagas";
import * as mensajesSagas from "./mensajes/sagas";

function* rootSaga() {
	yield all([
		takeLatest(actionTypes.APP_BOOT_INIT, appSagas.processAppBootInit),
		takeEvery(actionTypes.APP_ANUNCIOS_HOME_LOAD_INIT, appSagas.processAppAnunciosHomeLoadInit),
		takeLatest(actionTypes.AUTH_LOCAL_LOGIN_INIT, authSagas.processLocalLoginInit),
		takeLatest(actionTypes.AUTH_SILENT_LOGIN_SUCCESS, authSagas.processSilentLoginSuccess),
		takeLatest(actionTypes.AUTH_SILENT_LOGIN_ERROR, authSagas.processSilentLoginError),
		takeLatest(actionTypes.AUTH_LOGOUT, authSagas.processLogout),
		takeLatest(actionTypes.AUTH_PERFIL_SAVE_INIT, authSagas.processPerfilSaveInit),
		takeLatest(actionTypes.AUTH_LOCAL_REGISTER_VALIDATE_INIT, authSagas.processLocalRegisterValidateInit),
		takeLatest(actionTypes.AUTH_LOCAL_REGISTER_INIT, authSagas.processLocalRegisterInit),
		takeLatest(actionTypes.AUTH_SOCIAL_LOGIN_INIT, authSagas.processSocialLoginInit),
		takeLatest(actionTypes.AUTH_PASSWORD_RECOVERY_INIT, authSagas.processPasswordRecoveryInit),
		takeLatest(actionTypes.AUTH_PASSWORD_CHANGE_INIT, authSagas.processPasswordChangeInit),
		takeLatest(actionTypes.CREAR_ANUNCIO_VALIDATE_INIT, crearAnuncioSagas.processCrearAnuncioValidateInit),
		takeLatest(actionTypes.CREAR_ANUNCIO_INIT, crearAnuncioSagas.processCrearAnuncioInit),
		takeLatest(actionTypes.ANUNCIOS_PROPIOS_LOAD_INIT, anunciosSagas.processAnunciosPropiosLoadInit),
		takeLatest(actionTypes.ANUNCIOS_AJENOS_LOAD_INIT, anunciosSagas.processAnunciosAjenosLoadInit),
		takeLatest(actionTypes.ANUNCIO_LOAD_INIT, anunciosSagas.processAnuncioLoadInit),
		takeLatest(actionTypes.ANUNCIO_SAVE_INIT, anunciosSagas.processAnuncioSaveInit),
		takeLatest(actionTypes.ANUNCIO_DELETE_INIT, anunciosSagas.processAnuncioDeleteInit),
		takeLatest(actionTypes.ANUNCIOHOTELES_LOAD_INIT, anunciosSagas.processAnuncioHotelesLoadInit),
		takeLatest(actionTypes.ANUNCIOCONTACTO_INIT, anunciosSagas.processAnuncioContactoInit),
		takeLatest(actionTypes.CONTACTO_INIT, contactoSagas.processContactoInit),
		takeLatest(actionTypes.NEWSLETTER_SUSCRIPCION_ADD_INIT, newsletterSagas.processNewsletterSuscripcionAddInit),
		takeLatest(actionTypes.PAGINAS_LOAD_INIT, paginasSagas.processPaginasLoadInit),
		takeLatest(actionTypes.MENSAJES_THREADS_LOAD_INIT, mensajesSagas.processMensajesThreadsLoadInit),
		takeLatest(actionTypes.MENSAJES_LOAD_INIT, mensajesSagas.processMensajesLoadInit),
		takeLatest(actionTypes.MENSAJES_SAVE_INIT, mensajesSagas.processMensajesSaveInit),
	]);
}

export default rootSaga;
