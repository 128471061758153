import actionTypes from "../actionTypes";

const initialState = {
	boot: {
		paises: [],
		tablas: [],
		hoteles: [],
		terminos: {
			title: "",
			body: "",
		},
		areas: [],
		strings: {},
		home_areas_banners: null,
		loaded: false,
	},
	network: {
		error: false,
		loading: false,
	},
	ui: {
		sideNav: false,
		dropdownMenu: false,
		popupLogin: false,
		popupEliminarAnuncio: false,
		popupEliminarAnuncioId: null,
		popupEliminarAnuncioTitle: "",
		popupTooltip: false,
		popupFiltroZona: false,
		popupMasFiltros: false,
		popupOrdenar: false,
		popupEligeComunidades: false,
		popupPeticionMensaje: false,
		popupHome: false,
		popupTexto: false,
		popupTextoTitle: "",
		popupTextoBody: "",
		popupTextoButton: "",
		popupTypeform: { show: false, typeformData: null },
		popupMap: { show: false, location: { lat: null, long: null } },
		popupVideo: { show: false, videoId: null },
		errors: false,
		errorsList: [],
		success: false,
		successTitle: "",
		successMessage: "",
	},
	uiSubpages: {
		registerPage: 1,
		crearAnuncioPage: 1,
	},
	events: {
		registerReady: false,
		registerDone: false,
		crearAnuncioReady: false,
		crearAnuncioDone: false,
		contactoLoading: false,
		contactoDone: false,
		anuncioContactoDone: false,
		anuncioContactoLoading: false,
		newsletterSuscripcionAddDone: false,
		passwordRecoveryDone: false,
		passwordChangeDone: false,
	},
	redirectTo: null,
	redirectOptions: null,
	language: null,
	geo: {
		lat: null,
		long: null,
		descripcion: "",
	},
	area: null, // null o objeto completo de area
	mode: null, // null, geo, area,
	anunciosHome: [],
	isMobile: false,
};

const appReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		// app boot

		case actionTypes.APP_BOOT_INIT:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: true,
				},
				language: payload,
			};

		case actionTypes.APP_BOOT_SUCCESS:
			return {
				...state,
				boot: {
					...state.boot,
					...payload,
					loaded: true,
				},
				network: {
					...state.network,
					error: false,
					loading: false,
				},
			};

		case actionTypes.APP_BOOT_ERROR:
			return {
				...state,
				boot: {
					...state.boot,
					loaded: false,
				},
				network: {
					...state.network,
					error: true,
					loading: false,
				},
			};

		// app network

		case actionTypes.APP_NETWORK_ERROR_RESET:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
			};

		// auth perfil save

		case actionTypes.AUTH_PERFIL_SAVE_INIT:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: true,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.AUTH_PERFIL_SAVE_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					// popupLogin: false,
					errors: false,
					errorsList: initialState.ui.errorsList,
					successTitle: state.boot.strings.success.perfilsave.title,
					successMessage: state.boot.strings.success.perfilsave.message,
					success: true,
				},
			};

		case actionTypes.AUTH_PERFIL_SAVE_ERROR:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.perfilsave[error]) return state.boot.strings.errors?.perfilsave[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		// auth local register

		case actionTypes.AUTH_LOCAL_REGISTER_INIT:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: true,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					registerReady: false,
					registerDone: false,
				},
			};

		case actionTypes.AUTH_LOCAL_REGISTER_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					popupLogin: false,
					errors: false,
					errorsList: initialState.ui.errorsList,
					successTitle: state.boot.strings.success.localregister.title,
					successMessage: state.boot.strings.success.localregister.message,
					success: true,
				},
				events: {
					...state.events,
					registerReady: false,
					registerDone: true,
				},
			};

		case actionTypes.AUTH_LOCAL_REGISTER_ERROR:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.localregister[error])
							return state.boot.strings.errors?.localregister[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					registerReady: false,
					registerDone: false,
				},
			};

		case actionTypes.AUTH_LOCAL_REGISTER_RESET:
			return {
				...state,
				uiSubpages: {
					...state.uiSubpages,
					registerPage: initialState.uiSubpages.registerPage,
				},
			};

		// auto silent login

		case actionTypes.AUTH_SILENT_LOGIN_SUCCESS:
			return {
				...state,
				events: {
					...state.events,
					registerReady: false,
					registerDone: false,
				},
			};

		case actionTypes.AUTH_SILENT_LOGIN_ERROR:
			return {
				...state,
				events: {
					...state.events,
					registerReady: false,
					registerDone: false,
				},
			};

		// auth local login

		case actionTypes.AUTH_LOCAL_LOGIN_INIT:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: true,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.AUTH_LOCAL_LOGIN_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					popupLogin: false,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.AUTH_LOCAL_LOGIN_ERROR:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.locallogin[error]) return state.boot.strings.errors?.locallogin[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		// auth social login

		case actionTypes.AUTH_SOCIAL_LOGIN_INIT:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: true,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.AUTH_SOCIAL_LOGIN_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					popupLogin: false,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.AUTH_SOCIAL_LOGIN_ERROR:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.sociallogin[error])
							return state.boot.strings.errors?.sociallogin[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		// auth logout

		case actionTypes.AUTH_LOGOUT:
			return {
				...state,
				events: {
					...state.events,
					registerReady: false,
					registerDone: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: true,
					successTitle: state.boot.strings.success.logout.title,
					successMessage: state.boot.strings.success.logout.message.replace("%name%", payload.nombre),
				},
			};

		// app ui sidenav

		case actionTypes.APP_UI_SIDENAV_TOGGLE:
			return {
				...state,
				ui: {
					...state.ui,
					sideNav: !state.ui.sideNav,
				},
			};

		// app ui dropdown menu

		case actionTypes.APP_UI_DROPDOWNMENU_TOGGLE:
			return {
				...state,
				ui: {
					...state.ui,
					dropdownMenu: !state.ui.dropdownMenu,
				},
			};

		// app ui popup login

		case actionTypes.APP_UI_POPUP_LOGIN_SHOW: {
			return {
				...state,
				ui: {
					...initialState.ui,
					popupLogin: true,
				},
			};
		}

		case actionTypes.APP_UI_POPUP_LOGIN_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup eliminar anuncio

		case actionTypes.APP_UI_POPUP_ELIMINAR_ANUNCIO_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupEliminarAnuncio: true,
					popupEliminarAnuncioId: payload.id,
					popupEliminarAnuncioTitle: payload.title,
				},
			};

		case actionTypes.APP_UI_POPUP_ELIMINAR_ANUNCIO_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup tooltip

		case actionTypes.APP_UI_POPUP_TOOLTIP_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupTooltip: true,
				},
			};

		case actionTypes.APP_UI_POPUP_TOOLTIP_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup filtrozona

		case actionTypes.APP_UI_POPUP_FILTROZONA_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupFiltroZona: true,
				},
			};

		case actionTypes.APP_UI_POPUP_FILTROZONA_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup masfiltros

		case actionTypes.APP_UI_POPUP_MASFILTROS_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupMasFiltros: true,
				},
			};

		case actionTypes.APP_UI_POPUP_MASFILTROS_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup ordenar

		case actionTypes.APP_UI_POPUP_ORDENAR_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupOrdenar: true,
				},
			};

		case actionTypes.APP_UI_POPUP_ORDENAR_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup eligecomunidades

		case actionTypes.APP_UI_POPUP_ELIGECOMUNIDADES_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupEligeComunidades: true,
				},
			};

		case actionTypes.APP_UI_POPUP_ELIGECOMUNIDADES_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup petición mensaje

		case actionTypes.APP_UI_POPUP_PETICION_MENSAJE_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupPeticionMensaje: true,
				},
			};

		case actionTypes.APP_UI_POPUP_PETICION_MENSAJE_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup home

		case actionTypes.APP_UI_POPUP_HOME_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupHome: true,
				},
			};

		case actionTypes.APP_UI_POPUP_HOME_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup texto

		case actionTypes.APP_UI_POPUP_TEXTO_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupTexto: true,
					popupTextoTitle: payload.title,
					popupTextoBody: payload.body,
					popupTextoButton: payload.button,
				},
			};

		case actionTypes.APP_UI_POPUP_TEXTO_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
				},
			};

		// app ui popup typeform
		case actionTypes.APP_UI_POPUP_TYPEFORM_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupTypeform: { show: true, typeformData: payload },
				},
			};
		case actionTypes.APP_UI_POPUP_TYPEFORM_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupTypeform: initialState.ui.popupTypeform,
				},
			};
		// app ui popup map
		case actionTypes.APP_UI_POPUP_MAP_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupMap: { show: true, location: payload },
				},
			};
		case actionTypes.APP_UI_POPUP_MAP_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupMap: { show: false, location: null },
				},
			};

		// app ui popup video
		case actionTypes.APP_UI_POPUP_VIDEO_SHOW:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupVideo: { show: true, videoId: payload },
				},
			};
		case actionTypes.APP_UI_POPUP_VIDEO_HIDE:
			return {
				...state,
				ui: {
					...initialState.ui,
					popupMap: { show: false, videoId: null },
				},
			};

		case actionTypes.APP_UI_ERRORS_SHOW:
			return {
				...state,
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.APP_UI_ERRORS_HIDE:
			return {
				...state,
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.APP_UI_SUCCESS_HIDE:
			return {
				...state,
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		// app geo

		case actionTypes.APP_GEO_SET:
			// localStorage.setItem("geo", JSON.stringify(payload));
			return {
				...state,
				mode: "geo",
				geo: payload,
				area: initialState.area,
			};

		// app area

		case actionTypes.APP_AREA_SET:
			return {
				...state,
				mode: "area",
				area: payload,
				geo: initialState.geo,
			};

		// auth local register validate

		case actionTypes.AUTH_LOCAL_REGISTER_VALIDATE_INIT:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: true,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.AUTH_LOCAL_REGISTER_VALIDATE_SUCCESS:
			if (state.uiSubpages.registerPage < 4) {
				// todavia no llego a la pagina 4, incrementar pagina
				return {
					...state,
					ui: {
						...state.ui,
						errors: false,
						errorsList: initialState.ui.errorsList,
						success: false,
						successTitle: initialState.ui.successTitle,
						successMessage: initialState.ui.successMessage,
					},
					uiSubpages: {
						...state.uiSubpages,
						registerPage: state.uiSubpages.registerPage + 1,
					},
					network: {
						...state.network,
						error: false,
						loading: false,
					},
					events: {
						...state.events,
						registerReady: false,
						registerDone: false,
					},
				};
			} else {
				// acaba de validar pagina 4, ya puede iniciar el registro
				return {
					...state,
					ui: {
						...state.ui,
						errors: false,
						errorsList: initialState.ui.errorsList,
						success: false,
						successTitle: initialState.ui.successTitle,
						successMessage: initialState.ui.successMessage,
					},
					network: {
						...state.network,
						error: false,
						loading: false,
					},
					events: {
						...state.events,
						registerReady: true,
						registerDone: false,
					},
				};
			}

		case actionTypes.AUTH_LOCAL_REGISTER_VALIDATE_ERROR:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.localregistervalidate[error])
							return state.boot.strings.errors?.localregistervalidate[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		// auth local register page

		case actionTypes.AUTH_LOCAL_REGISTER_PAGE_BACK:
			return {
				...state,
				uiSubpages: {
					...state.uiSubpages,
					registerPage:
						state.uiSubpages.registerPage > initialState.uiSubpages.registerPage
							? state.uiSubpages.registerPage - 1
							: initialState.uiSubpages.registerPage,
				},
			};

		// auth password recovery

		case actionTypes.AUTH_PASSWORD_RECOVERY_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					passwordRecoveryDone: false,
				},
			};

		case actionTypes.AUTH_PASSWORD_RECOVERY_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: true,
					successTitle: state.boot.strings.success.passwordrecovery.title,
					successMessage: state.boot.strings.success.passwordrecovery.message,
				},
				events: {
					...state.events,
					passwordRecoveryDone: true,
				},
			};

		case actionTypes.AUTH_PASSWORD_RECOVERY_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.passwordrecovery[error])
							return state.boot.strings.errors?.passwordrecovery[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					passwordRecoveryDone: false,
				},
			};

		// auth password change

		case actionTypes.AUTH_PASSWORD_CHANGE_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					passwordChangeDone: false,
				},
			};

		case actionTypes.AUTH_PASSWORD_CHANGE_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: true,
					successTitle: state.boot.strings.success.passwordchange.title,
					successMessage: state.boot.strings.success.passwordchange.message,
				},
				events: {
					...state.events,
					passwordChangeDone: true,
				},
			};

		case actionTypes.AUTH_PASSWORD_CHANGE_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.passwordchange[error])
							return state.boot.strings.errors?.passwordchange[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					passwordChangeDone: false,
				},
			};

		// crear anuncio validate

		case actionTypes.CREAR_ANUNCIO_VALIDATE_INIT:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: true,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.CREAR_ANUNCIO_VALIDATE_SUCCESS:
			if (state.uiSubpages.crearAnuncioPage < 5) {
				// todavia no llego a la pagina 5, incrementar pagina
				return {
					...state,
					ui: {
						...state.ui,
						errors: false,
						errorsList: initialState.ui.errorsList,
						success: false,
						successTitle: initialState.ui.successTitle,
						successMessage: initialState.ui.successMessage,
					},
					uiSubpages: {
						...state.uiSubpages,
						crearAnuncioPage: state.uiSubpages.crearAnuncioPage + 1,
					},
					network: {
						...state.network,
						error: false,
						loading: false,
					},
					events: {
						...state.events,
						crearAnuncioReady: false,
						crearAnuncioDone: false,
					},
				};
			} else {
				// acaba de validar pagina 5, ya puede crear el anuncio
				return {
					...state,
					ui: {
						...state.ui,
						errors: false,
						errorsList: initialState.ui.errorsList,
						success: false,
						successTitle: initialState.ui.successTitle,
						successMessage: initialState.ui.successMessage,
					},
					network: {
						...state.network,
						error: false,
						loading: false,
					},
					events: {
						...state.events,
						crearAnuncioReady: true,
						crearAnuncioDone: false,
					},
				};
			}

		case actionTypes.CREAR_ANUNCIO_VALIDATE_ERROR:
			return {
				...state,
				network: {
					...state.network,
					error: false,
					loading: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.crearanunciovalidate[error])
							return state.boot.strings.errors?.crearanunciovalidate[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					crearAnuncioReady: false,
					crearAnuncioDone: false,
				},
			};

		// crear anuncio page

		case actionTypes.CREAR_ANUNCIO_PAGE_BACK:
			return {
				...state,
				uiSubpages: {
					...state.uiSubpages,
					crearAnuncioPage:
						state.uiSubpages.crearAnuncioPage > initialState.uiSubpages.crearAnuncioPage
							? state.uiSubpages.crearAnuncioPage - 1
							: initialState.uiSubpages.crearAnuncioPage,
				},
			};

		// crear anuncio

		case actionTypes.CREAR_ANUNCIO_INIT:
			return {
				...state,
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					crearAnuncioReady: false,
					crearAnuncioDone: false,
				},
			};

		case actionTypes.CREAR_ANUNCIO_SUCCESS:
			return {
				...state,
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					crearAnuncioReady: false,
					crearAnuncioDone: true,
				},
			};

		case actionTypes.CREAR_ANUNCIO_ERROR:
			return {
				...state,
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.crearanuncio[error])
							return state.boot.strings.errors?.crearanuncio[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					crearAnuncioReady: false,
					crearAnuncioDone: false,
				},
			};

		case actionTypes.CREAR_ANUNCIO_RESET:
			return {
				...state,
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				uiSubpages: {
					...state.uiSubpages,
					crearAnuncioPage: initialState.uiSubpages.crearAnuncioPage,
				},
				events: {
					...state.events,
					crearAnuncioReady: false,
					crearAnuncioDone: false,
				},
			};

		// anuncios propios load

		case actionTypes.ANUNCIOS_PROPIOS_LOAD_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
			};

		case actionTypes.ANUNCIOS_PROPIOS_LOAD_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
			};

		case actionTypes.ANUNCIOS_PROPIOS_LOAD_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
			};

		// anuncios ajenos load

		case actionTypes.ANUNCIOS_AJENOS_LOAD_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
			};

		case actionTypes.ANUNCIOS_AJENOS_LOAD_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
			};

		case actionTypes.ANUNCIOS_AJENOS_LOAD_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
			};

		// anuncio load

		case actionTypes.ANUNCIO_LOAD_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
			};

		case actionTypes.ANUNCIO_LOAD_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
			};

		case actionTypes.ANUNCIO_LOAD_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
			};

		// anuncio save

		case actionTypes.ANUNCIO_SAVE_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.ANUNCIO_SAVE_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: true,
					successTitle: state.boot.strings.success.anunciosave.title,
					successMessage: state.boot.strings.success.anunciosave.message,
				},
			};

		case actionTypes.ANUNCIO_SAVE_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.anunciosave[error])
							return state.boot.strings.errors?.anunciosave[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		// anuncio delete

		case actionTypes.ANUNCIO_DELETE_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		case actionTypes.ANUNCIO_DELETE_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					popupEliminarAnuncio: false,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: true,
					successTitle: state.boot.strings.success.anunciodelete.title,
					successMessage: state.boot.strings.success.anunciodelete.message,
				},
			};

		case actionTypes.ANUNCIO_DELETE_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					popupEliminarAnuncio: false,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.anunciodelete[error])
							return state.boot.strings.errors?.anunciodelete[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
			};

		// anuncio contacto

		case actionTypes.ANUNCIOCONTACTO_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					anuncioContactoLoading: true,
					anuncioContactoDone: false,
				},
			};

		case actionTypes.ANUNCIOCONTACTO_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: true,
					successTitle: state.boot.strings.success.anunciocontacto.title,
					successMessage: state.boot.strings.success.anunciocontacto.message,
				},
				events: {
					...state.events,
					anuncioContactoLoading: false,
					anuncioContactoDone: true,
				},
			};

		case actionTypes.ANUNCIOCONTACTO_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.anunciocontacto[error])
							return state.boot.strings.errors?.anunciocontacto[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					anuncioContactoLoading: false,
					anuncioContactoDone: false,
				},
			};

		// contacto

		case actionTypes.CONTACTO_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					contactoLoading: true,
					contactoDone: false,
				},
			};

		case actionTypes.CONTACTO_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: true,
					successTitle: state.boot.strings.success.contactosend.title,
					successMessage: state.boot.strings.success.contactosend.message,
				},
				events: {
					...state.events,
					contactoLoading: false,
					contactoDone: true,
				},
			};

		case actionTypes.CONTACTO_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.contactosend[error])
							return state.boot.strings.errors?.contactosend[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					contactoLoading: false,
					contactoDone: false,
				},
			};

		// newsletter suscripcion add

		case actionTypes.NEWSLETTER_SUSCRIPCION_ADD_INIT:
			return {
				...state,
				network: {
					...state.network,
					loading: true,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					newsletterSuscripcionAddDone: false,
				},
			};

		case actionTypes.NEWSLETTER_SUSCRIPCION_ADD_SUCCESS:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: false,
					errorsList: initialState.ui.errorsList,
					success: true,
					successTitle: state.boot.strings.success.newslettersuscripcionadd.title,
					successMessage: state.boot.strings.success.newslettersuscripcionadd.message,
				},
				events: {
					...state.events,
					newsletterSuscripcionAddDone: true,
				},
			};

		case actionTypes.NEWSLETTER_SUSCRIPCION_ADD_ERROR:
			return {
				...state,
				network: {
					...state.network,
					loading: false,
					error: false,
				},
				ui: {
					...state.ui,
					errors: true,
					errorsList: payload.errors.map((error) => {
						if (state.boot.strings.errors?.newslettersuscripcionadd[error])
							return state.boot.strings.errors?.newslettersuscripcionadd[error];
						else return null;
					}),
					success: false,
					successTitle: initialState.ui.successTitle,
					successMessage: initialState.ui.successMessage,
				},
				events: {
					...state.events,
					newsletterSuscripcionAddDone: false,
				},
			};

		// anuncios home

		case actionTypes.APP_ANUNCIOS_HOME_INIT:
			return {
				...state,
				anunciosHome: initialState.anunciosHome,
			};

		case actionTypes.APP_ANUNCIOS_HOME_LOAD_SUCCESS:
			return {
				...state,
				anunciosHome: [...state.anunciosHome, payload],
			};

		// app redirect

		case actionTypes.APP_REDIRECT:
			return {
				...state,
				redirectTo: payload.to,
				redirectOptions: payload.options,
			};

		case actionTypes.APP_REDIRECT_RESET:
			return {
				...state,
				redirectTo: initialState.redirectTo,
				redirectOptions: initialState.redirectOptions,
			};

		// app is mobile
		case actionTypes.APP_SET_IS_MOBILE:
			return {
				...state,
				isMobile: payload,
			};

		default:
			return state;
	}
};

export default appReducer;
