import actionTypes from "../actionTypes";

const initialState = {
	propios: [],
	ajenos: [],
	ajenosLoading: false,
	ajenosPagination: {
		total: 0,
		page: 0,
		pages: 0,
		offset: 0,
		limit: 0,
	},
	search: {
		operacion: "alquiler",
		tabla: null,
		desde: 0,
		hasta: 30,
		page: 1,
		orden: null,
	},
	anuncio: null,
	anuncioHoteles: [],
	anuncioHotelesPagination: {
		total: 0,
		page: 0,
		pages: 0,
		offset: 0,
		limit: 0,
	},
};

const anunciosReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		// anuncios propios

		case actionTypes.ANUNCIOS_PROPIOS_LOAD_SUCCESS:
			return {
				...state,
				propios: payload.data,
			};

		case actionTypes.ANUNCIOS_PROPIOS_LOAD_ERROR:
			return {
				...state,
				propios: initialState.propios,
			};

		// anuncios ajenos

		case actionTypes.ANUNCIOS_AJENOS_SEARCH_SET:
			return {
				...state,
				search: {
					...state.search,
					...payload,
				},
			};

		case actionTypes.ANUNCIOS_AJENOS_RESET:
			return {
				...state,
				ajenos: initialState.ajenos,
				ajenosLoading: initialState.ajenosLoading,
			};

		case actionTypes.ANUNCIOS_AJENOS_LOAD_INIT:
			return {
				...state,
				ajenosLoading: true,
			};

		case actionTypes.ANUNCIOS_AJENOS_LOAD_SUCCESS:
			return {
				...state,
				ajenos: payload.data.page === 1 ? payload.data.anuncios : [...state.ajenos, ...payload.data.anuncios],
				ajenosPagination: {
					...state.ajenosPagination,
					total: payload.data.total,
					page: payload.data.page,
					pages: payload.data.pages,
					offset: payload.data.offset,
					limit: payload.data.limit,
				},
				ajenosLoading: initialState.ajenosLoading,
			};

		case actionTypes.ANUNCIOS_AJENOS_LOAD_ERROR:
			return {
				...state,
				ajenosLoading: initialState.ajenosLoading,
			};

		// anuncio

		case actionTypes.ANUNCIO_LOAD_SUCCESS:
			return {
				...state,
				anuncio: payload.data,
			};

		case actionTypes.ANUNCIO_LOAD_ERROR:
			return {
				...state,
				anuncio: initialState.anuncio,
			};

		case actionTypes.ANUNCIOHOTELES_LOAD_SUCCESS:
			return {
				...state,
				anuncioHoteles:
					payload.data.page === 1 ? payload.data.hoteles : [...state.anuncioHoteles, ...payload.data.hoteles],
				anuncioHotelesPagination: {
					...state.anuncioHotelesPagination,
					total: payload.data.total,
					page: payload.data.page,
					pages: payload.data.pages,
					offset: payload.data.offset,
					limit: payload.data.limit,
				},
			};

		case actionTypes.ANUNCIO_RESET:
			return {
				...state,
				anuncio: initialState.anuncio,
				anuncioHoteles: initialState.anuncioHoteles,
			};

		default:
			return state;
	}
};

export default anunciosReducer;
