import axios from "axios";

// const apiHost = process.env.GATSBY_SYB_API_HOST || "https://api.shareyourboard.app/";
const apiHost = "https://api.shareyourboard.app/";
// const apiHost = "http://localhost:8004/";
// const apiHost = "https://sybapi.crowdland.app/";
const apiEndpoint = `${apiHost}<lang>/api/`;

const apiBoot = `${apiEndpoint}boot/`;
const apiLocalLogin = `${apiEndpoint}locallogin/`;
const apiGoogleLogin = `${apiEndpoint}googlelogin/`;
const apiAppleLogin = `${apiEndpoint}applelogin/`;
const apiPerfil = `${apiEndpoint}perfil/`;
const apiLocalRegisterValidate = `${apiEndpoint}localregistervalidate/`;
const apiCrearAnuncioValidate = `${apiEndpoint}crearanunciovalidate/`;
const apiAnuncios = `${apiEndpoint}anuncios/`;
const apiAnunciosSearch = `${apiEndpoint}anunciossearch/`;
const apiAnuncioContacto = `${apiEndpoint}anunciocontacto/`;
const apiContacto = `${apiEndpoint}contacto/`;
const apiNewsletter = `${apiEndpoint}newsletter/`;
const apiPasswordRecovery = `${apiEndpoint}passwordrecovery/`;
const apiPasswordChange = `${apiEndpoint}passwordchange/`;
const apiPaginas = `${apiEndpoint}paginas/`;
const apiHotelesSearch = `${apiEndpoint}hotelessearch/`;
const apiMensajes = `${apiEndpoint}mensajes/`;
const apiMensajesThreads = `${apiEndpoint}mensajesthreads/`;

class API {
	static getBoot = (lang) => {
		console.log("API/getBoot");
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(apiBoot.replace("<lang>", lang), {
					timeout: 10000,
				});
				console.log("API/getBoot Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getBoot Response ERROR", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getBoot Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getBoot Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static localLogin = (lang, email, password) => {
		console.log("API/localLogin", email, password);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiLocalLogin.replace("<lang>", lang), {
					email,
					password,
				});
				console.log("API/localLogin Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/localLogin Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/localLogin Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/localLogin Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static socialLogin = (lang, provider, token) => {
		console.log("API/socialLogin", provider, token);
		if (["google", "apple"].indexOf(provider) > -1) {
			var apiSocialLogin = null;
			switch (provider) {
				case "google":
					apiSocialLogin = apiGoogleLogin;
					break;
				case "apple":
					apiSocialLogin = apiAppleLogin;
					break;
				default:
					return null;
			}
			return new Promise(async (resolve, reject) => {
				try {
					const response = await axios.post(apiSocialLogin.replace("<lang>", lang), {
						token,
						timezoneOffset: new Date().getTimezoneOffset(),
					});
					console.log("API/socialLogin Response OK", response);
					resolve(response.data.data.response.payload);
				} catch (error) {
					if (error.response) {
						console.log("API/socialLogin Response ERROR Response", error.response.data);
						reject(error.response.data);
					} else if (error.request) {
						console.log("API/socialLogin Response ERROR Request", error.request);
						reject(null);
					} else {
						console.log("API/socialLogin Response ERROR", error);
						reject(null);
					}
				}
			});
		} else {
			return new Promise(async (resolve, reject) => {
				reject(null);
			});
		}
	};

	static getPerfil = (lang, token) => {
		console.log("API/getPerfil", token);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(`${apiPerfil.replace("<lang>", lang)}?token=${token}`);
				console.log("API/getPerfil Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getPerfil Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getPerfil Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/getPerfil Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static savePerfil = (lang, data) => {
		console.log("API/guardarPerfil", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.patch(apiPerfil.replace("<lang>", lang), data);
				console.log("API/guardarPerfil Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/guardarPerfil Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/guardarPerfil Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/guardarPerfil Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static localRegisterValidate = (lang, data) => {
		console.log("API/registerValidate", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiLocalRegisterValidate.replace("<lang>", lang), data);
				console.log("API/registerValidate Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/registerValidate Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/registerValidate Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/registerValidate Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static localRegister = (lang, data) => {
		console.log("API/localRegister", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiPerfil.replace("<lang>", lang), data);
				console.log("API/localRegister Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/localRegister Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/localRegister Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/localRegister Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static passwordRecovery = (lang, data) => {
		console.log("API/passwordRecovery", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiPasswordRecovery.replace("<lang>", lang), data);
				console.log("API/passwordRecovery Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/passwordRecovery Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/passwordRecovery Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/passwordRecovery Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static passwordChange = (lang, data) => {
		console.log("API/passwordChange", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiPasswordChange.replace("<lang>", lang), data);
				console.log("API/passwordChange Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/passwordChange Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/passwordChange Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/passwordChange Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static crearAnuncioValidate = (lang, data) => {
		console.log("API/crearAnuncioValidate", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiCrearAnuncioValidate.replace("<lang>", lang), data);
				console.log("API/crearAnuncioValidate Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/crearAnuncioValidate Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/crearAnuncioValidate Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/crearAnuncioValidate Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static crearAnuncio = (lang, data) => {
		console.log("API/crearAnuncio", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiAnuncios.replace("<lang>", lang), data);
				console.log("API/crearAnuncio Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/crearAnuncio Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/crearAnuncio Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/crearAnuncio Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getAnuncios = (lang, token = null, params = {}) => {
		console.log("API/getAnuncios", lang, token, params);
		let url = apiAnunciosSearch.replace("<lang>", lang) + "?";
		if (token) url += `token=${token}&`;
		if (params.lat) url += `lat=${params.lat}&`;
		if (params.long) url += `long=${params.long}&`;
		if (params.operacion) url += `operacion=${params.operacion}&`;
		if (params.tabla) url += `tabla=${params.tabla}&`;
		if (params.desde) url += `desde=${params.desde}&`;
		if (params.hasta) url += `hasta=${params.hasta}&`;
		if (params.page) url += `page=${params.page}&`;
		if (params.limit) url += `limit=${params.limit}&`;
		if (params.orden) url += `orden=${params.orden}&`;
		if (params.mode) url += `mode=${params.mode}&`;
		if (params.area) url += `area=${params.area}&`;
		console.log("API/getAnuncios", url);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(url);
				console.log("API/getAnuncios Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getAnuncios Response ERROR", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getAnuncios Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getAnuncios Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getAnuncio = (lang, id) => {
		console.log("API/getAnuncio", id);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(`${apiAnuncios.replace("<lang>", lang)}?id=${id}`);
				console.log("API/getAnuncio Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getAnuncio Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getAnuncio Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/getAnuncio Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getHoteles = (lang, params = {}) => {
		console.log("API/getHoteles", lang, params);
		let url = apiHotelesSearch.replace("<lang>", lang) + "?";
		if (params.mode) url += `mode=${params.mode}&`;
		if (params.lat) url += `lat=${params.lat}&`;
		if (params.long) url += `long=${params.long}&`;
		if (params.area) url += `area=${params.area}&`;
		if (params.page) url += `page=${params.page}&`;
		if (params.limit) url += `limit=${params.limit}&`;
		if (params.orden) url += `orden=${params.orden}&`;
		console.log("API/getHoteles", url);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(url);
				console.log("API/getHoteles Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getHoteles Response ERROR", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getHoteles Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getHoteles Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static saveAnuncio = (lang, data) => {
		console.log("API/saveAnuncio", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.patch(apiAnuncios.replace("<lang>", lang), data);
				console.log("API/saveAnuncio Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/saveAnuncio Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/saveAnuncio Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/saveAnuncio Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static deleteAnuncio = (lang, data) => {
		console.log("API/deleteAnuncio", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.delete(
					`${apiAnuncios.replace("<lang>", lang)}?id=${data.id}&token=${data.token}`
				);
				console.log("API/deleteAnuncio Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/deleteAnuncio Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/deleteAnuncio Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/deleteAnuncio Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static anuncioContacto = (lang, data) => {
		console.log("API/anuncioContacto", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiAnuncioContacto.replace("<lang>", lang), data);
				console.log("API/anuncioContacto Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/anuncioContacto Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/anuncioContacto Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/anuncioContacto Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static enviarContacto = (lang, data) => {
		console.log("API/enviarContacto", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiContacto.replace("<lang>", lang), data);
				console.log("API/enviarContacto Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/enviarContacto Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/enviarContacto Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/enviarContacto Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static newsletterSuscripcionAdd = (lang, data) => {
		console.log("API/newsletterSuscripcionAdd", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiNewsletter.replace("<lang>", lang), data);
				console.log("API/newsletterSuscripcionAdd Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/newsletterSuscripcionAdd Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/newsletterSuscripcionAdd Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/newsletterSuscripcionAdd Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getPaginas = (lang, name) => {
		console.log("API/getPaginas", name);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(`${apiPaginas.replace("<lang>", lang)}?name=${name}`);
				console.log("API/getPaginas Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getPaginas Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getPaginas Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/getPaginas Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getMensajesThreads = (lang, token) => {
		console.log("API/getMensajesThreads", lang, token);
		let url = apiMensajesThreads.replace("<lang>", lang) + `?token=${token}`;
		console.log("API/getMensajesThreads", url);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(url);
				console.log("API/getMensajesThreads Response OK", response);
				resolve(response.data.data.response.payload.data);
			} catch (error) {
				if (error.response) {
					console.log("API/getMensajesThreads Response ERROR", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getMensajesThreads Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getMensajesThreads Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getMensajes = (lang, token, anuncio) => {
		console.log("API/getMensajes", lang, token);
		let url = apiMensajes.replace("<lang>", lang) + `?token=${token}&anuncio=${anuncio}`;
		console.log("API/getMensajes", url);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(url);
				console.log("API/getMensajes Response OK", response);
				resolve(response.data.data.response.payload.data);
			} catch (error) {
				if (error.response) {
					console.log("API/getMensajes Response ERROR", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getMensajes Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getMensajes Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static mensajesSave = (lang, data) => {
		console.log("API/mensajeSave", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiMensajes.replace("<lang>", lang), data);
				console.log("API/mensajeSave Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/mensajeSave Response ERROR Response", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/mensajeSave Response ERROR Request", error.request);
					reject(null);
				} else {
					console.log("API/mensajeSave Response ERROR", error);
					reject(null);
				}
			}
		});
	};
}

export default API;
