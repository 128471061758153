import actionTypes from "../actionTypes";
import { put, call } from "redux-saga/effects";
import API from "../../classes/api";

export function* processPaginasLoadInit(action) {
	const { language, name } = action.payload;
	try {
		const pagina = yield call(API.getPaginas, language, name);
		yield put({
			type: actionTypes.PAGINAS_LOAD_SUCCESS,
			payload: pagina.data,
		});
	} catch (error) {
		yield put({
			type: actionTypes.PAGINAS_LOAD_ERROR,
		});
	}
}
