import API from "../../classes/api";
import { put, call } from "redux-saga/effects";
import actionTypes from "../actionTypes";

export function* processNewsletterSuscripcionAddInit(action) {
	console.log("authSagas/processNewsletterSuscripcionAddInit", action);
	const { language, data } = action.payload;
	try {
		const response = yield call(API.newsletterSuscripcionAdd, language, data);
		yield put({
			type: actionTypes.NEWSLETTER_SUSCRIPCION_ADD_SUCCESS,
			payload: response,
		});
	} catch (error) {
		yield put({
			type: actionTypes.NEWSLETTER_SUSCRIPCION_ADD_ERROR,
			payload: error.data.response.payload,
		});
	}
}
