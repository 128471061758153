import { combineReducers } from "redux";
import app from "./app/reducer";
import auth from "./auth/reducer";
import anuncios from "./anuncios/reducer";
import paginas from "./paginas/reducer";
import mensajes from "./mensajes/reducer";

const reducers = combineReducers({
	app,
	auth,
	anuncios,
	paginas,
	mensajes,
});

export default reducers;
